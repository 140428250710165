<template>
  <div v-if="financialDetailsLoading === true">
    <CRow>
      <CCol cols="12" lg="12">
        <loadingSpinner mode="auto" :content="$t('admin.Loading_financial_details')"/>
      </CCol>
    </CRow>      
  </div>  
  <div v-else>
    <CRow class="m-0">
      <CCol cols="12" lg="12">
        <span class="d-block mb-2">{{$t('admin.Company_payment_status')}}</span>        
        <b-switch class="m-0" v-model="companyPaymentStatus" size="is-small" @input="updateCompanyPaymentStatus();">
          <span>{{companyPaymentStatus ? $t('common.Paid') : $t('common.Unpaid')}}</span>
        </b-switch>        
      </CCol>
    </CRow>
    <hr class="mt-0 mb-0">
    <CRow class="m-0 financial_details">
      <CCol cols="12" lg="12" class="pt-0 pb-0">
        <b-tabs class="module_tabs no_borders mb-0" type="is-boxed" :animated="false" v-model="activeTab">
          <!-- Wallet Balance -->
          <b-tab-item>
            <template slot="header">
              <span>{{$t('admin.Company_wallet')}}</span>
            </template>
            <template>
              <CRow class="m-0">
                <CCol cols="12" lg="12">
                  <label class="m-0">{{$t('common.Current_wallet_balance')}}</label>
                  <div class="d-flex mt-1">
                    <div class="d-flex align-items-center currency">
                      <img v-if="companyWalletCurrency === 'coins'" :src="apiBaseUrl + '/v1/common/cdn/file/image/base-dashboard/credits/' + environmentTag + '_credit.png/' + clientToken"/>
                      <i v-if="companyWalletCurrency === 'euro'" class="fa-solid fa-euro-sign"></i>
                    </div>
                    <div v-if="!companyData.payment_setup_completed || (companyData.payment_setup_completed && companyData.payment_type_tag === 'prepaid')" class="ml-1 d-flex align-items-center currency">
                      <span class="points">{{companyWalletCurrency === 'coins' ? companyWalletCoins : formatToCurrency(companyWalletCoins)}}</span>
                    </div>
                    <div v-if="companyData.payment_setup_completed && companyData.payment_type_tag === 'recurring'" class="ml-1 d-flex align-items-center currency">
                      <span class="points"><i class="fa-solid fa-infinity"></i></span>
                    </div>
                    <div v-if="!companyData.payment_setup_completed || (companyData.payment_setup_completed && companyData.payment_type_tag === 'prepaid')" @click="switchCompanyWalletCurrency()" class="ml-1 d-flex align-items-center pointer">
                      <i class="fa-solid fa-repeat"></i>
                    </div>
                  </div>                  
                </CCol>
              </CRow>              
              <CRow v-if="!companyData.payment_setup_completed || (companyData.payment_setup_completed && companyData.payment_type_tag === 'prepaid')" class="m-0">
                <CCol cols="12" lg="12" class="pt-0">
                  <label>{{$t('admin.Add_points_to_company_wallet')}}</label>
                  <div class="d-flex align-items-center">
                    <CInput type="number" min="0" step="100" class="flex-grow-1 mb-0" v-model="loyaltyPointsAmount"/>
                    <CButton color="primary" @click="updateCompanyWalletBalance();" class="ml-2" :disabled="loyaltyPointsAmount <= 0">
                      <span><i class="fa-solid fa-save mr-1"></i>{{$t('admin.Update_balance')}}</span>
                    </CButton>    
                  </div>
                </CCol>
              </CRow>
            </template>
          </b-tab-item>          
          <!-- Invoice details -->
          <b-tab-item v-if="companyData.payment_setup_completed">
            <template slot="header">
              <span>{{$t('payments.Invoice_details')}}</span>
            </template>
            <template>
              <CRow class="m-0">
                <CCol cols="6" lg="6" class="pb-0">
                  <CInput :label="$t('core.company_name')" type="text" class="mb-0" v-model="financialDetails.invoice_company_name" required was-validated/>
                </CCol>
                <CCol cols="6" lg="6" class="pb-0">
                  <CInput :label="$t('common.Email_address')" type="email" class="mb-0" v-model="financialDetails.invoice_company_email" required was-validated/>
                </CCol>                              
              </CRow>
              <CRow class="m-0">
                <CCol cols="4" lg="4" class="pb-0">
                  <CInput :label="$t('common.Address')" type="text" class="mb-0" v-model="financialDetails.invoice_company_address" required was-validated/>
                </CCol>
                <CCol cols="2" lg="2" class="pb-0">
                  <CInput :label="$t('common.Zip_code')" type="text" class="mb-0" v-model="financialDetails.invoice_company_zip_code" required was-validated/>
                </CCol>
                <CCol cols="3" lg="3" class="pb-0">
                  <CInput :label="$t('common.Place')" type="text" class="mb-0" v-model="financialDetails.invoice_company_place" required was-validated/>
                </CCol>
                <CCol cols="3" lg="3" class="pb-0">
                  <label>{{$t('common.Country')}}</label>
                  <multiselect class="open_absolute"
                              v-model="financialDetails.invoice_company_country" 
                              :options="countries" 
                              :multiple="false"
                              :hideSelected="false"
                              :closeOnSelect="true"
                              track-by="code" 
                              label="name"
                              :show-labels="false">
                    <span slot="noResult">{{$t('common.no_countries_found')}}</span>

                    <template slot="singleLabel" slot-scope="country">
                      <span v-if="country.option.emoji" class="language_flag" v-html="getTwemoji(country.option.emoji)"></span>
                      <span class="language_name">{{ country.option.name }}</span>
                    </template>

                    <template slot="option" slot-scope="country">
                      <span v-if="country.option.emoji" class="language_flag" v-html="getTwemoji(country.option.emoji)"></span>
                      <span class="language_name">{{ country.option.name }}</span>
                    </template>
                  </multiselect>
                </CCol>
              </CRow>
              <CRow class="m-0">
                <CCol cols="6" lg="6" class="pb-0">
                  <CInput :label="$t('common.Coc_number')" type="text" class="mb-0" v-model="financialDetails.invoice_company_coc_number"/>
                </CCol>
                <CCol cols="6" lg="6" class="pb-0">
                  <CInput :label="$t('common.VAT_number')" type="text" class="mb-0" v-model="financialDetails.invoice_company_vat_number"/>
                </CCol>
              </CRow>
              <CRow class="m-0">
                <CCol cols="12" lg="12">
                  <CTextarea :label="$t('payments.PO_number_reference')" rows="3" class="mb-0" v-model="financialDetails.invoice_company_po_number_reference"/>
                </CCol>
              </CRow>
              <hr class="m-0">
              <CRow class="m-0">
                <CCol cols="12" lg="12">
                  <CButton color="primary" @click="updateCompanyFinancialDetails();" class="m-0">
                    <span><i class="fa-solid fa-save mr-1"></i>{{$t('admin.Update_financial_details')}}</span>
                  </CButton>        
                </CCol>
              </CRow>              
            </template>
          </b-tab-item>
          <!-- Financial contact -->
          <b-tab-item v-if="companyData.payment_setup_completed">
            <template slot="header">
              <span>{{$t('payments.Financial_contact')}}</span>
            </template>
            <template>
              <CRow class="m-0">
                <CCol cols="3" lg="3" class="pb-0">
                  <CInput :label="$t('common.First_name')" type="text" class="mb-0" v-model="financialDetails.financial_contact_first_name" required was-validated/>
                </CCol>
                <CCol cols="3" lg="3" class="pb-0">
                  <CInput :label="$t('common.Last_name')" type="text" class="mb-0" v-model="financialDetails.financial_contact_last_name" required was-validated/>
                </CCol>
                <CCol cols="6" lg="6" class="pb-0">
                  <CInput :label="$t('common.Email_address')" type="email" class="mb-0" v-model="financialDetails.financial_contact_email" required was-validated/>
                </CCol>
              </CRow>
              <CRow class="m-0">
                <CCol cols="6" lg="6" class="pb-0">
                  <CInput :label="$t('common.Function')" type="text" class="mb-0" v-model="financialDetails.financial_contact_function"/>
                </CCol>                              
                <CCol cols="6" lg="6">
                  <CInput :label="$t('common.Phone_number')" type="text" class="mb-0" v-model="financialDetails.financial_contact_phone"/>
                </CCol>
              </CRow>
              <hr class="m-0">
              <CRow class="m-0">
                <CCol cols="12" lg="12">
                  <CButton color="primary" @click="updateCompanyFinancialDetails();" class="m-0">
                    <span><i class="fa-solid fa-save mr-1"></i>{{$t('admin.Update_financial_details')}}</span>
                  </CButton>        
                </CCol>
              </CRow>              
            </template>
          </b-tab-item>
          <!-- WeFact -->
          <b-tab-item v-if="companyData.payment_setup_completed">
            <template slot="header">
              <span>{{$t('payments.WeFact')}}</span>
            </template>
            <template>
              <CRow class="m-0">
                <CCol cols="12" lg="12">
                  <label>{{$t('payments.Debtor_code')}}</label>
                  <div class="d-flex align-items-center">
                    <CInput type="text" class="flex-grow-1 mb-0" v-model="financialDetails.invoice_debtor_code"/>
                    <CButton color="primary" @click="updateInvoiceDebtorCode();" class="ml-2">
                      <span><i class="fa-solid fa-save mr-1"></i>{{$t('admin.Update_debtor_code')}}</span>
                    </CButton>    
                  </div>
                </CCol>
              </CRow>
            </template>
          </b-tab-item>            
          <!-- Mollie -->
          <b-tab-item v-if="companyData.payment_setup_completed && financialDetails.payment_provider_tag === 'mollie'">
            <template slot="header">
              <span>{{$t('payments.Mollie')}}</span>
            </template>
            <template>
              <CRow class="m-0">
                <CCol cols="12" lg="12" :class="{ 'pb-0' : companyData.payment_type_tag === 'recurring' }">
                  <label>{{$t('payments.Customer_id')}}</label>
                  <div class="d-flex align-items-center">
                    <CInput type="text" class="flex-grow-1 mb-0" v-model="financialDetails.payment_customer_id"/>
                    <CButton color="primary" @click="updatePaymentCustomerId();" class="ml-2">
                      <span><i class="fa-solid fa-save mr-1"></i>{{$t('admin.Update_customer_id')}}</span>
                    </CButton>    
                  </div>
                </CCol>
              </CRow>
              <CRow v-if="companyData.payment_type_tag === 'recurring'" class="m-0">
                <CCol cols="12" lg="12">
                  <label>{{$t('payments.Mandate_id')}}</label>
                  <div class="d-flex align-items-center">
                    <CInput type="text" class="flex-grow-1 mb-0" v-model="financialDetails.payment_mandate_id"/>
                    <CButton color="primary" @click="updatePaymentMandateId();" class="ml-2">
                      <span><i class="fa-solid fa-save mr-1"></i>{{$t('admin.Update_mandate_id')}}</span>
                    </CButton>    
                  </div>
                </CCol>
              </CRow>                                
            </template>
          </b-tab-item>
          <!-- Recurring Payments -->
          <b-tab-item v-if="companyData.payment_setup_completed && companyData.payment_type_tag === 'recurring'">
            <template slot="header">
              <span>{{$t('payments.Recurring_payments')}}</span>
            </template>
            <template>
              <CRow class="m-0">
                <CCol cols="12" lg="12">
                  <span class="d-block mb-2">{{$t('admin.Invoice_loyalty_points_with_orders')}}</span>        
                  <b-switch class="m-0" v-model="invoiceLoyaltyPointsWithOrders" size="is-small" @input="confirmModal = true;">
                    <span>{{invoiceLoyaltyPointsWithOrders ? $t('Yes') : $t('No')}}</span>
                  </b-switch>
                  
                  <b-modal :can-cancel="['x']" :active.sync="confirmModal" :width="960" scroll="keep">
                    <CCard class="mb-0">
                      <CCardHeader class="pb-0">
                        <span>{{invoiceLoyaltyPointsWithOrders ? $t('admin.Enable_invoicing_loyalty_points_with_orders') : $t('admin.Disable_invoicing_loyalty_points_with_orders')}}</span>
                      </CCardHeader>
                      <CCardBody>
                        <CRow>
                          <CCol cols="12" lg="12" class="pt-0 pb-0">
                            <span>{{invoiceLoyaltyPointsWithOrders ? $t('admin.Are_you_sure_to_enable_invoicing_loyalty_points_with_orders', { company_name : companyData.details.company_name}) : $t('admin.Are_you_sure_to_disable_invoicing_loyalty_points_with_orders', { company_name : companyData.details.company_name})}}</span>
                          </CCol>
                        </CRow>
                      </CCardBody>
                      <CCardFooter>
                        <CButton color="primary" @click="updateCompanyFinancialDetails(); confirmModal = false;"><i class="fas fa-check mr-1"/>{{invoiceLoyaltyPointsWithOrders ? $t('admin.Enable_invoicing_with_orders') : $t('admin.Disable_invoicing_with_orders')}}</CButton>
                        <CButton color="secondary" @click="invoiceLoyaltyPointsWithOrders = !invoiceLoyaltyPointsWithOrders; confirmModal = false;"><i class="fas fa-times mr-1"/>{{ $t('Cancel') }}</CButton>
                      </CCardFooter>
                    </CCard>
                  </b-modal>    
                </CCol>
              </CRow>
            </template>
          </b-tab-item>
        </b-tabs>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import loadingSpinner from '@/components/common/loadingSpinner.vue';

export default {
  name: 'CompanyFinancialDetails',
  props: ['companyData', 'countries'],
  watch: {
    companyData: {
      handler() {
        if(this.companyData.payment_status) this.companyPaymentStatus = this.companyData.payment_status;
      },
      deep: true,
      immediate: true,
    }
  },
  components: {
    Multiselect,
    loadingSpinner
  },  
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,      
      environmentTag: null,
      activeTab: 0,
      companyPaymentStatus: false,
      loyaltyPointsAmount: 0,
      companyWalletCurrency: 'coins',
      companyWalletCoins: 0,
      companyWalletAmount: 0,
      companyWalletAmountFormatted: 0,
      financialDetailsIdExternal: null,
      financialDetailsLoading: false,
      financialDetailsLoaded: false,
      financialDetails: {
        invoice: {},
        financial_contact: {},
        setup_completed: false
      },
      invoiceLoyaltyPointsWithOrders: false,
      confirmModal: false
    }
  },
  methods: {
    getCompanyFinancialDetails() {
      // Start the loader
      if(this.financialDetailsLoaded === false) this.financialDetailsLoading = true;
      // Get the financial details
      axios.get(process.env.VUE_APP_API_URL + '/v1/superadmin/company/financialdetails')
      .then(res => {      
        this.financialDetails = res.data.data;
        // Set the financialDetailsIdExternal value if data is available
        if(this.financialDetails.company_financial_details_id_external) this.financialDetailsIdExternal = this.financialDetails.company_financial_details_id_external;
        // Set the invoice_loyalty_points_with_orders value
        this.invoiceLoyaltyPointsWithOrders = this.financialDetails.invoice_loyalty_points_with_orders === 'Y' ? true : false;
        // Stop the loader
        this.financialDetailsLoading = false;
        // Update the financialDetailsLoaded value
        this.financialDetailsLoaded = true;
      })
      .catch(err => {
        console.error(err);
      });      
    },
    getCompanyWallet() {
      // Get the points      
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/wallet')
      .then(res => {
        this.companyWalletCoins = res.data.data.loyalty_wallet_coins;
        this.companyWalletAmount = res.data.data.loyalty_wallet_amount;
        this.companyWalletAmountFormatted = res.data.data.loyalty_wallet_amount_formatted;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    updateCompanyWalletBalance() {
      // Set the params
      let params = {};
      params.points_amount = this.loyaltyPointsAmount;
      // Save the financial details of the company
      axios.put(process.env.VUE_APP_API_URL + '/v1/superadmin/company/wallet/balance', params)
      .then(res => { 
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('admin.Company_wallet_balance_updated'), type: 'is-success', duration: 2000 });
        // Update the company wallet
        this.getCompanyWallet();
        // Reset the loyaltyPointsAmount value
        this.loyaltyPointsAmount = 0;
      })
      .catch(err => {
        console.error(err);
      });      
    },   
    updateCompanyFinancialDetails() {
      // Set the params
      let params = {};
      params.financialDetailsData = this.financialDetails;
      params.financialDetailsData.invoice_loyalty_points_with_orders = this.invoiceLoyaltyPointsWithOrders === true ? 'Y' : 'N';
      // Save the financial details of the company
      axios.put(process.env.VUE_APP_API_URL + '/v1/superadmin/company/financialdetails/' + this.financialDetailsIdExternal, params)
      .then(res => { 
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('admin.Financial_details_updated'), type: 'is-success', duration: 2000 });
        // Update the company details
        this.getCompanyFinancialDetails();
      })
      .catch(err => {
        console.error(err);
      });
    },
    updateInvoiceDebtorCode() {
      // Set the params
      let params = {};
      params.invoiceDebtorCode = this.financialDetails.invoice_debtor_code;
      // Save the financial details of the company
      axios.put(process.env.VUE_APP_API_URL + '/v1/superadmin/company/financialdetails/' + this.financialDetailsIdExternal + '/invoice/debtor/code', params)
      .then(res => { 
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('admin.Debtor_code_updated'), type: 'is-success', duration: 2000 });
      })
      .catch(err => {
        console.error(err);
      });
    },
    updatePaymentCustomerId() {
      // Set the params
      let params = {};
      params.paymentCustomerId = this.financialDetails.payment_customer_id;
      // Save the financial details of the company
      axios.put(process.env.VUE_APP_API_URL + '/v1/superadmin/company/financialdetails/' + this.financialDetailsIdExternal + '/payments/provider/customer', params)
      .then(res => { 
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('admin.Customer_id_updated'), type: 'is-success', duration: 2000 });
      })
      .catch(err => {
        console.error(err);
      });
    },
    updatePaymentMandateId() {
      // Set the params
      let params = {};
      params.paymentMandateId = this.financialDetails.payment_mandate_id;
      // Save the financial details of the company
      axios.put(process.env.VUE_APP_API_URL + '/v1/superadmin/company/financialdetails/' + this.financialDetailsIdExternal + '/payments/provider/mandate', params)
      .then(res => { 
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('admin.Mandate_id_updated'), type: 'is-success', duration: 2000 });
      })
      .catch(err => {
        console.error(err);
      });
    },            
    updateCompanyPaymentStatus() {
      // Set the params
      let params = {};
      params.paymentStatus = this.companyPaymentStatus;      
      // Save the financial details of the company
      axios.put(process.env.VUE_APP_API_URL + '/v1/superadmin/company/payment/status', params)
      .then(res => {
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('admin.Company_payment_status_updated'), type: 'is-success', duration: 2000 });
      })
      .catch(err => {
        console.error(err);
      });
    },
    switchCompanyWalletCurrency() {
      (this.companyWalletCurrency === 'coins') ? this.companyWalletCurrency = 'euro' : this.companyWalletCurrency = 'coins';
    },
    formatToCurrency(points) {
      return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(parseInt(points)/10);
    },        
    getTwemoji(emoji) {
      return twemoji.parse(emoji);
    }    
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
    if(localStorage.getItem('environmentTag') !== null) this.environmentTag = localStorage.getItem('environmentTag');

    this.getCompanyFinancialDetails();
    this.getCompanyWallet();
  }
}
</script>